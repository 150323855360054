import withWindowDimensions from "components/common/withWindowDimensions"
import { withLogin } from "components/Login/withLogin"
import useDevice from "hooks/useDevice"
import Image from "next/image"
import Link from "next/link"
import { navRoutes } from "page_routes"
import React, { FunctionComponent } from "react"
import { trackClick } from "scripts/segment"
import { pageName } from "utils/events-utils"
import { osName } from "react-device-detect"
import FooterLinks from "components/common/FooterLinks"
import FooterHtmlSiteMap from "components/common/FooterHtmlSiteMap"

const links = {
  countries: [
    {
      country: "USA",
      link: "/usa",
    },
    {
      country: "UK",
      link: "/uk",
    },
    {
      country: "Canada",
      link: "/canada",
    },
    {
      country: "Australia",
      link: "/australia",
    },
    {
      country: "Ireland",
      link: "/ireland",
    },
    {
      country: "Germany",
      link: "/germany",
    },
  ],
  topUniversities: [
    {
      text: "Top Universities in USA",
      link: "/usa/universities-colleges-list",
    },
    {
      text: "Top Universities in UK",
      link: "/uk/universities-colleges-list",
    },
    {
      text: "Top Universities in Canada",
      link: "/canada/universities-colleges-list",
    },
    {
      text: "Top Universities in Australia",
      link: "/australia/universities-colleges-list",
    },
    {
      text: "Top Universities in Ireland",
      link: "/ireland/universities-colleges-list",
    },
  ],
  offerings: [
    {
      text: "Free IELTS Masterclass",
      path: "/ielts-free-online-coaching",
    },
    {
      text: "Talk to a counsellor",
      path: "/counsellors",
    },
    {
      text: "Twinning Programs",
      link: "https://advantage.leapscholar.com",
    },
    {
      text: "Cost Calculator",
      path: "/cost-calculator",
    },
    {
      text: "Blog",
      link: "https://leapscholar.com/blog/",
    },
    {
      text: "Study Abroad Knowledge Center",
      link: "https://leapscholar.com/blog/study-abroad-knowledge-center-sitemap/",
    },
    {
      text: "Events",
      path: "/events",
    },
  ],
  testimonials: [
    {
      path: "/testimonials/ielts",
      contentName: "IELTS",
    },
    {
      path: "/testimonials/scholar",
      contentName: "Counselling",
    },
  ],
  company: [
    {
      text: "About Us",
      path: "/about-us",
    },
    {
      text: "Careers",
      link: "https://careers.leapfinance.com",
    },
    {
      text: "Leap in the news!",
      link: "https://press.leapscholar.com",
    },
  ],
  products: [
    {
      text: "LeapFinance",
      link: "https://leapfinance.com",
    },
    {
      text: "LeapAdvantage",
      link: "https://advantage.leapscholar.com",
    },
    {
      text: "IELTS Prep by LeapScholar",
      link: "/ielts-preparation-online",
    },
  ],
  socialMedia: [
    {
      icon: "https://leapassets.s3.ap-south-1.amazonaws.com/youtube.svg",
      link: "https://www.youtube.com/c/leapscholar",
      contentName: "Youtube",
    },
    {
      icon: "https://leapassets.s3.ap-south-1.amazonaws.com/twitter.svg",
      link: "https://twitter.com/leapscholar",
      contentName: "Twitter",
    },
    {
      icon: "https://leapassets.s3.ap-south-1.amazonaws.com/linkedin.svg",
      link: "https://www.linkedin.com/company/leap-global-education/",
      contentName: "Linkedin",
    },
    {
      icon: "https://leapassets.s3.ap-south-1.amazonaws.com/instagram.svg",
      link: "https://www.instagram.com/leapscholar",
      contentName: "Instagram",
    },
    {
      icon: "https://leapassets.s3.ap-south-1.amazonaws.com/facebook.svg",
      link: "https://www.facebook.com/leapscholar",
      contentName: "Facebook",
    },
    {
      icon: "https://leapassets.s3.ap-south-1.amazonaws.com/telegram-icon.svg",
      link: "https://t.me/leap_abroad",
      contentName: "Telegram",
    },
  ],
  partners: [
    {
      link: "https://yocket.com",
      contentName: "Yocket study abroad",
    },
    {
      link: "https://geebeeworld.com",
      contentName: "GEEBEE",
    },
  ],
  exams: [
    {
      text: "IELTS",
      link: "https://leapscholar.com/exams/ielts?flow=footer",
    },
    {
      text: "TOEFL",
      link: "https://leapscholar.com/exams/toefl?flow=footer",
    },
    {
      text: "SAT",
      link: "https://leapscholar.com/exams/sat?flow=footer",
    },
    {
      text: "PTE",
      link: "https://leapscholar.com/exams/pte?flow=footer",
    },
    {
      text: "GRE",
      link: "https://leapscholar.com/exams/gre?flow=footer",
    },
    {
      text: "GMAT",
      link: "https://leapscholar.com/exams/gmat?flow=footer",
    },
  ],
}

const getWidget = (contentName?: string, widgetName?: string) => {
  return {
    contentName,
    widgetName,
    widgetFormat: "Banner",
    contentFormat: "Button",
  }
}

const Divider: FunctionComponent = () => (
  <div className="h-100 bg-gray-780 w-full my-6"></div>
)

const ContactSection: React.FC<FooterProps> = () => {
  return (
    <div className="flex flex-col gap-1 text-lightgray-250 break-words mt-4">
      <p className="font-bold text-normal">Contact us</p>

      <a
        className="text-font14 text-lightgray-1650 flex gap-1 mt-1"
        href="tel:08047184844"
      >
        <img
          className="self-start"
          src="https://leapassets.s3.ap-south-1.amazonaws.com/navbar/footer-call.svg"
          alt="call"
        />
        <div className="flex gap-3">
          <p> Call us on:</p>
          <div>
            <strong>+91 080-47184844</strong>
            <p>(Between 10 am to 7 pm)</p>
          </div>
        </div>
      </a>
      <a
        className="text-font14 text-lightgray-1650 flex gap-1 items-center"
        href="mailto:contact@leapscholar.com"
      >
        <img
          src="https://leapassets.s3.ap-south-1.amazonaws.com/navbar/footer-mail.svg"
          alt="call"
        />

        <div className="flex gap-3">
          <p> Mail us on:</p>
          <div>
            <strong>contact@leapscholar.com</strong>
          </div>
        </div>
      </a>
    </div>
  )
}

interface FooterProps {
  windowWidth?: number
  isMobile?: boolean
}

const osSpecificLink = {
  Android: {
    image:
      "https://leapassets.s3.ap-south-1.amazonaws.com/navbar/google-play.svg",
    link: "https://play.google.com/store/apps/details?id=com.leapscholar.app",
  },
  iOS: {
    image:
      "https://leapassets.s3.ap-south-1.amazonaws.com/navbar/app-store.svg",
    link: "https://apps.apple.com/in/app/counselling-by-leapscholar/id1618752229",
  },
}

const AppLink = ({ image, link }: { image: string; link: string }) => {
  return (
    <a target="_blank" rel="noopener noreferrer" href={link}>
      <img src={image} alt="download" />
    </a>
  )
}

const Footer = () => {
  const { isMobile, isTablet } = useDevice()

  return (
    <>
      <footer
        className={`bg-blue-100 text-white ${
          isMobile ? "py-5 px-[22px]" : "p-[36px]"
        }`}
      >
        <div className={!isMobile && !isTablet ? "wrapper" : ""}>
          <div className="flex flex-col gap-6">
            {/* logo */}
            <div className="flex items-center gap-2">
              <img
                className="logo"
                src={`${process.env.CDN_URL}/assets/company_logo/new-footer-logo.svg`}
                alt="logo"
                style={{ width: "200px" }}
              />
            </div>

            {/* Social links */}
            <div className="flex flex-col gap-1 text-lightgray-250">
              <div className="flex gap-2 mt-2">
                {links.socialMedia.map((element, index) => (
                  <a
                    onClick={() =>
                      trackClick(
                        pageName.LeapScholarHomePage,
                        getWidget(element.contentName, "Footer"),
                      )
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                    href={element.link}
                  >
                    <Image
                      src={element.icon}
                      alt="social-icon"
                      width={30}
                      height={30}
                    />
                  </a>
                ))}
              </div>
            </div>

            {/* Download app section */}
            <div className="flex flex-col gap-6 mt-4">
              <div>
                <p className="font-bold text-normal mb-3">
                  Download Study Abroad App
                </p>

                <div className="flex gap-4">
                  {isMobile ? (
                    <>
                      {osName === "Android" ? (
                        <AppLink
                          image={osSpecificLink.Android.image}
                          link={osSpecificLink.Android.link}
                        />
                      ) : osName === "iOS" ? (
                        <AppLink
                          image={osSpecificLink.iOS.image}
                          link={osSpecificLink.iOS.link}
                        />
                      ) : (
                        <>
                          <AppLink
                            image={osSpecificLink.Android.image}
                            link={osSpecificLink.Android.link}
                          />
                          <AppLink
                            image={osSpecificLink.iOS.image}
                            link={osSpecificLink.iOS.link}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <AppLink
                        image={osSpecificLink.Android.image}
                        link={osSpecificLink.Android.link}
                      />
                      <AppLink
                        image={osSpecificLink.iOS.image}
                        link={osSpecificLink.iOS.link}
                      />
                    </>
                  )}
                </div>
              </div>

              <div>
                <p className="font-bold text-normal mb-3">
                  Download IELTS Prep App
                </p>

                <AppLink
                  image={
                    "https://leapassets.s3.ap-south-1.amazonaws.com/navbar/google-play.svg"
                  }
                  link={"https://leapielts.app.link/open"}
                />
              </div>
            </div>

            <ContactSection />
          </div>

          <div className="flex-1">
            <span className="md:hidden">
              <Divider />
            </span>
            <FooterLinks />
            <Divider />

            <div
              className={`grid ${
                !isMobile ? "md:grid-cols-4" : "grid-cols-2 gap-5 mt-8"
              }`}
            >
              {/* Countries */}
              <div className="flex flex-col gap-1 text-lightgray-250">
                <p className="font-bold text-normal">Countries</p>
                {links.countries.map((element, index) => (
                  <a
                    key={index}
                    href={`${element.link}`}
                    onClick={() => {
                      trackClick(
                        pageName.LeapScholarHomePage,
                        getWidget(element.country, "Footer - Countries List"),
                      )
                    }}
                    className="text-font14 text-lightgray-1650"
                  >
                    {element.country}
                  </a>
                ))}

                <div className="flex flex-col text-lightgray-250 mt-2">
                  <p className="font-bold text-normal">Top Universities</p>
                  {links.topUniversities.map((element, index) => (
                    <a
                      key={index}
                      href={`${element.link}`}
                      className="text-font14 text-lightgray-1650"
                    >
                      {element.text}
                    </a>
                  ))}
                </div>
              </div>

              {/* Company */}
              <div className="flex flex-col gap-1 text-lightgray-250">
                <p className="font-bold text-normal">Company</p>
                {links.company.map((element, index) =>
                  element.link ? (
                    <a
                      onClick={() => {
                        trackClick(
                          pageName.LeapScholarHomePage,
                          getWidget(element.text, "Footer - Company"),
                        )
                      }}
                      className="text-font14 text-lightgray-1650"
                      target="_blank"
                      rel="noopener noreferrer"
                      key={index}
                      href={element.link}
                    >
                      {element.text}
                    </a>
                  ) : (
                    <Link key={index} href={`${element.path}`} prefetch={false}>
                      <a
                        onClick={() => {
                          trackClick(
                            pageName.LeapScholarHomePage,
                            getWidget(element.text, "Footer - Company"),
                          )
                        }}
                        className="text-font14 text-lightgray-1650"
                      >
                        {element.text}
                      </a>
                    </Link>
                  ),
                )}

                {!isMobile && (
                  <div className="flex flex-col text-lightgray-250 mt-2">
                    <p className="font-bold text-normal">Our Partners</p>
                    {links.partners.map((element, index) =>
                      element.link ? (
                        <a
                          onClick={() => {
                            trackClick(
                              pageName.LeapScholarHomePage,
                              getWidget(
                                element.contentName,
                                "Footer - Partner",
                              ),
                            )
                          }}
                          className="text-font14 text-lightgray-1650"
                          target="_blank"
                          rel="noopener noreferrer"
                          key={index}
                          href={element.link}
                        >
                          {element.contentName}
                        </a>
                      ) : (
                        <Link
                          key={index}
                          href={`${element.link}`}
                          prefetch={false}
                        >
                          <a
                            onClick={() => {
                              trackClick(
                                pageName.LeapScholarHomePage,
                                getWidget(
                                  element.contentName,
                                  "Footer - Partner",
                                ),
                              )
                            }}
                            className="text-font14 text-lightgray-1650"
                          >
                            {element.contentName}
                          </a>
                        </Link>
                      ),
                    )}
                  </div>
                )}
              </div>

              {/* Offerings */}
              <div className="flex flex-col gap-1 text-lightgray-250">
                <p className="font-bold text-normal">Our Offerings</p>
                {links.offerings.map((element, index) =>
                  element.link ? (
                    element.link === "/" ? (
                      <a
                        onClick={() => {
                          trackClick(
                            pageName.LeapScholarHomePage,
                            getWidget(element.text, "Footer - Our Offerings"),
                          )
                        }}
                        className="text-font14 text-lightgray-1650"
                        key={index}
                        href="/"
                      >
                        {element.text}
                      </a>
                    ) : (
                      <a
                        onClick={() => {
                          trackClick(
                            pageName.LeapScholarHomePage,
                            getWidget(element.text, "Footer - Our Offerings"),
                          )
                        }}
                        className="text-font14 text-lightgray-1650 w-[85%]"
                        target="_blank"
                        rel="noopener noreferrer"
                        key={index}
                        href={element.link}
                      >
                        {element.text}
                      </a>
                    )
                  ) : (
                    <Link key={index} href={`${element.path}`} prefetch={false}>
                      <a
                        className="text-font14 text-lightgray-1650"
                        onClick={() => {
                          trackClick(
                            pageName.LeapScholarHomePage,
                            getWidget(element.text, "Footer - Our Offerings"),
                          )
                        }}
                      >
                        {element.text}
                      </a>
                    </Link>
                  ),
                )}
                <div className="flex flex-col text-gray-13 mt-2">
                  <p className="font-bold text-[15px]">Testimonials</p>
                  {links.testimonials.map((element, index) => (
                    <Link key={index} href={`${element.path}`} prefetch={false}>
                      <a
                        className="text-font14 text-lightgray-1650"
                        onClick={() => {
                          trackClick(
                            pageName.LeapScholarHomePage,
                            getWidget(
                              element.contentName,
                              "Footer - Testimonials",
                            ),
                          )
                        }}
                      >
                        {element.contentName}
                      </a>
                    </Link>
                  ))}
                </div>
              </div>

              {/* Products */}
              <div className="flex flex-col gap-1 text-lightgray-250">
                <p className="font-bold text-normal">Our Products</p>
                {links.products.map((element, index) => (
                  <a
                    onClick={() => {
                      trackClick(
                        pageName.LeapScholarHomePage,
                        getWidget(element.text, "Footer - Our Products"),
                      )
                    }}
                    className="text-font14 text-lightgray-1650"
                    rel="noopener noreferrer"
                    target={
                      element.text === "IELTS by Leap" ? "_self" : "_blank"
                    }
                    key={index}
                    href={element.link}
                  >
                    {element.text}
                  </a>
                ))}

                <div className="flex flex-col gap-1 text-lightgray-250 mt-2">
                  <p className="font-bold text-normal">Exam Information</p>
                  {links.exams.map((element, index) => (
                    <a
                      onClick={() => {
                        trackClick(
                          pageName.LeapScholarHomePage,
                          getWidget(element.text, "Footer - Exam Information"),
                        )
                      }}
                      className="text-font14 text-lightgray-1650"
                      rel="noopener noreferrer"
                      target={
                        element.text === "IELTS by Leap" ? "_self" : "_blank"
                      }
                      key={index}
                      href={element.link}
                    >
                      {element.text}
                    </a>
                  ))}
                </div>
              </div>
            </div>

            <Divider />

            <div>
              {/* Locations */}
              <div className={!isMobile ? "grid md:grid-cols-4" : ""}>
                <div className="flex flex-col gap-1 text-lightgray-250 break-words">
                  <p className="font-bold text-normal">
                    HQ- Bangalore &nbsp;(India)
                  </p>
                  <p
                    className={`text-font13 text-lightgray-1650 ${
                      !isMobile && "max-w-[148px]"
                    }`}
                  >
                    163/A, 9th Main Rd, Sector 6, HSR Layout, Bengaluru,
                    Karnataka 560102
                  </p>
                </div>

                {isMobile ? <Divider /> : null}

                <div className="flex flex-col gap-1 text-lightgray-250 break-words">
                  <p className="font-bold text-normal">
                    Branch Office &nbsp;(Bangalore)
                  </p>
                  <p
                    className={`text-font13 text-lightgray-1650 ${
                      !isMobile && "max-w-[148px]"
                    }`}
                  >
                    No 144, 3rd floor, Shubharam Complex, Mahatma Gandhi Rd,
                    near Metro Station Trinity, above HDFC Bank, Craig Park
                    Layout, Ashok Nagar, Bengaluru, Karnataka 560001,
                    <br /> Contact Number: +919663730932
                  </p>
                </div>

                {isMobile ? <Divider /> : null}

                <div className="flex flex-col gap-1 text-lightgray-250 break-words">
                  <p className="font-bold text-normal">
                    Singapore &nbsp;(International)
                  </p>
                  <p
                    className={`text-font13 text-lightgray-1650 ${
                      !isMobile && "max-w-[148px]"
                    }`}
                  >
                    11 Collyer Quay #17-00 The Arcade, 049317, Singapore
                  </p>
                </div>

                {isMobile ? <Divider /> : null}

                <div className="flex flex-col gap-1 text-lightgray-250 break-words">
                  <p className="font-bold text-normal">
                    UAE &nbsp;(International)
                  </p>
                  <p
                    className={`text-font13 text-lightgray-1650 ${
                      !isMobile && "max-w-[148px]"
                    }`}
                  >
                    Office 1106, Arenco Tower, Sheikh Zayed Road, <br /> Media
                    City Dubai - 3087, UAE <br /> Contact Number: +971 501501220
                  </p>
                </div>

                {isMobile ? <Divider /> : null}

                {/* Our Partners */}
                <div className="flex flex-col gap-1 text-lightgray-250 md:hidden break-words">
                  <p className="font-bold text-normal">Our Partners</p>
                  {links.partners.map((element, index) =>
                    element.link ? (
                      <a
                        onClick={() => {
                          trackClick(
                            pageName.LeapScholarHomePage,
                            getWidget(element.contentName, "Footer - Partner"),
                          )
                        }}
                        className="text-font14 text-lightgray-1650"
                        target="_blank"
                        rel="noopener noreferrer"
                        key={index}
                        href={element.link}
                      >
                        {element.contentName}
                      </a>
                    ) : (
                      <Link
                        key={index}
                        href={`${element.link}`}
                        prefetch={false}
                      >
                        <a
                          onClick={() => {
                            trackClick(
                              pageName.LeapScholarHomePage,
                              getWidget(
                                element.contentName,
                                "Footer - Partner",
                              ),
                            )
                          }}
                          className="text-font14 text-lightgray-1650"
                        >
                          {element.contentName}
                        </a>
                      </Link>
                    ),
                  )}
                </div>

                {/* Privacy */}
                <div className="flex flex-col gap-1 text-lightgray-250 pt-4 md:pt-0 break-words">
                  <Link href="/privacy" prefetch={false}>
                    <a className={`text-sm text-lightgray-1650 inline-block`}>
                      Privacy Policy
                    </a>
                  </Link>
                  <Link href={navRoutes.TERM_AND_CONDITION} prefetch={false}>
                    {/* Terms and Conditions */}
                    <a className={`text-sm text-lightgray-1650 inline-block`}>
                      Terms and Conditions
                    </a>
                  </Link>
                  <Link href={navRoutes.REFUND_POLICY} prefetch={false}>
                    {/* Refund policy */}
                    <a className={`text-sm text-lightgray-1650 inline-block`}>
                      Refund policy
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterHtmlSiteMap />
      </footer>

      <style jsx>
        {`
          .logo {
            filter: brightness(0) invert(1);
            margin-left: -4px;
            height: 50px;
          }

          .wrapper {
            max-width: 1120px;
            margin: auto;
            display: flex;
            gap: 80px;
          }
        `}
      </style>
    </>
  )
}

export default withWindowDimensions(withLogin(Footer))
